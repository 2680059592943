import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import PropTypes from "prop-types";
import { Box, Button, Divider, Drawer, TextField } from "@material-ui/core";
import AddCardIcon from "@mui/icons-material/AddCard";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import useAuth from "../../hooks/useAuth";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import UsersIcon from "../../icons/Users";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PoliceIcon from "../../icons/Police";
import Logo from "../Logo";
import BarChartIcon from "@mui/icons-material/BarChart";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { useTranslation } from "react-i18next";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import CreditScoreIcon from "@material-ui/icons/CreditScore";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import DescriptionIcon from "@material-ui/icons/Description";
import HomeIcon from "@material-ui/icons/Home";
import TranslateIcon from "@material-ui/icons/Translate";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import SettingsIcon from "@material-ui/icons/Settings";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Summarize from "@material-ui/icons/Summarize";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PasswordIcon from "@material-ui/icons/Password";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HailIcon from "@mui/icons-material/Hail";
import ExposureIcon from "@mui/icons-material/Exposure";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import MicIcon from "@mui/icons-material/Mic";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import CampaignIcon from "@mui/icons-material/Campaign";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  LocalLaundryService,
  MapsHomeWork,
  AccountTree,
  Paid,
  Category,
} from "@material-ui/icons";
import ChairIcon from "@mui/icons-material/Chair";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import TableBarIcon from "@mui/icons-material/TableBar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaidIcon from "@mui/icons-material/Paid";
import EuroIcon from "@mui/icons-material/Euro";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PrintIcon from "@mui/icons-material/Print";

const DashboardSidebar = (props) => {
  const testRequest = () => {
    HttpService.get(UrlService.apiDomain() + `api/salaries-test`)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();
  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );

  const policy_record_tree = JSON.parse(
    localStorage.getItem("policy_record_tree")
  );
  const roles = [];
  JSON.parse(localStorage.getItem("my_user")).roles.forEach((r) => {
    roles.push(r.id);
  });

  const isAdmin = roles.includes(2);

  //Sections children

  const settings_children = [];
  const lidi_children = [];
  const dochazka_children = [];
  const agentury_children = [];
  const reports_children = [];
  const accommodation_children = [];
  const mzdy_children = [];
  const coordinator_children = [];
  const finance_children = [];
  const agency_settings_children = [];
  const column_settings_children = [];
  const maintenance_children = [];
  const customers_children = [];
  const employment_department_children = [];
  const main_children = [
    {
      title: t("Domovská stránka"),
      path: "/dashboard",
      icon: <HomeIcon fontSize="small" />,
    },
  ];

  //Sections

  const sections = [
    {
      title: t("Úvod"),
      items: main_children,
    },
  ];

  if (policy_action_tree.settings.includes("calendar")) {
    main_children.push({
      title: t("Kalendář"),
      path: "/dashboard/calendar",
      icon: <CalendarMonthIcon fontSize="small" />,
    });
  }
  if (
    policy_action_tree.customer_start_requirements.includes("recommendation")
  ) {
    main_children.push({
      title: t("Požadavky rozjezd firem"),
      path: "/dashboard/all_customer_requirements",
      icon: <AssignmentIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.employees.includes("browse"))
    sections.push({
      title: t("Lidí"),
      items: lidi_children,
    });
  if (policy_action_tree.settings.includes("customers_firms"))
    sections.push({
      title: t("Uživatele firmy"),
      items: customers_children,
    });

  if (policy_action_tree.employment_department_infos.includes("browse"))
    sections.push({
      title: t("Úřad práce"),
      items: employment_department_children,
    });

  if (policy_action_tree.agency_accommodations.includes("browse"))
    sections.push({
      title: t("Ubytování"),
      items: accommodation_children,
    });

  if (
    policy_action_tree.employees.includes("report_dochazky") ||
    policy_action_tree.employees.includes("report_dodavatele") ||
    policy_action_tree.requests.includes("browse") ||
    policy_action_tree.suppliers.includes("rewards_report") ||
    policy_action_tree.recruitment.includes("report") ||
    policy_action_tree.settings.includes("report_people") ||
    policy_action_tree.salaries.includes("personal_report")
  ) {
    sections.push({
      title: t("Reporty"),
      items: reports_children,
    });
  }

  if (policy_action_tree.settings.includes("mzdy"))
    sections.push({
      title: t("Mzdy"),
      items: mzdy_children,
    });

  if (policy_action_tree.settings.includes("coordinator"))
    sections.push({
      title: t("Koordinátor"),
      items: coordinator_children,
    });

  if (policy_action_tree.received_invoices.includes("browse"))
    sections.push({
      title: t("Finance"),
      items: finance_children,
    });

  if (policy_action_tree.settings.includes("browse"))
    sections.push({
      title: t("Nastavení"),
      items: settings_children,
    });

  // Pushing childrens

  //Lidi childrens

  if (policy_action_tree.employees.includes("browse"))
    lidi_children.push({
      title: t("Všechny lidí"),
      path: "/dashboard/employees",
      icon: <AccessibilityIcon fontSize="small" />,
    });
  if (policy_action_tree.employees.includes("docusign"))
    lidi_children.push({
      title: t("Lidí k podpisu"),
      path: "/dashboard/employees_to_sign",
      icon: <HowToRegIcon fontSize="small" />,
    });
  if (policy_action_tree.recruitment.includes("browse"))
    lidi_children.push({
      title: t("Nábor"),
      path: "/dashboard/recruitment",
      icon: <PersonAddIcon fontSize="small" />,
    });
  // if (policy_action_tree.recruitment.includes("rozdeleni"))
  //   lidi_children.push({
  //     title: t("Rozdělení"),
  //     path: "/dashboard/rozdeleni",
  //     icon: <DoubleArrowIcon fontSize="small" />,
  //   });

  if (policy_action_tree.employees.includes("dochazka")) {
    let allowed_customers = policy_record_tree.customers;

    let allowed_agencies = policy_record_tree.agencies;

    const customers = localStorage.getItem("customers");
    if (customers) {
      JSON.parse(customers).map((e) => {
        if (allowed_customers.includes(String(e.id)))
          dochazka_children.push({
            title: e.name,
            path: "/dashboard/dochazka/" + e.id,
          });
      });
    }

    JSON.parse(localStorage.getItem("agencies"))
      .filter((e) => e.agentura != 0)
      .map((e) => {
        if (allowed_agencies.includes(String(e.id)))
          agentury_children.push({
            title: e.name,
            path: "/dashboard/agentura/" + e.id,
          });
      });
  }

  if (policy_action_tree.employees.includes("dochazka"))
    lidi_children.push({
      title: t("Agentury"),
      path: "/dashboard/agentura/all",
      icon: <AccountBalanceIcon fontSize="small" />,
      children: agentury_children,
    });

  if (policy_action_tree.settings.includes("users"))
    lidi_children.push({
      title: t("Dodavatele"),
      path: "/dashboard/suppliers",
      icon: <BusinessCenterIcon fontSize="small" />,
    });

  if (user.company_role === "owner" || user.company_role === "manager")
    lidi_children.push({
      title: t("Vaše firma"),
      path: "/dashboard/your-company",
      icon: <BusinessCenterIcon fontSize="small" />,
    });

  if (policy_action_tree.vacancies.includes("browse"))
    lidi_children.push({
      title: t("Požadavky lidi"),
      path: "/dashboard/vacancies",
      icon: <HailIcon fontSize="small" />,
    });

  // Roports children

  if (policy_action_tree.employees.includes("report_dochazky"))
    reports_children.push({
      title: t("Report dochazky"),
      path: "/dashboard/report_dochazky",
      icon: <AccessAlarmsIcon fontSize="small" />,
    });

  if (policy_action_tree.employees.includes("report_dodavatele")) {
    reports_children.push({
      title: t("Můj report dodavatele"),
      path: "/dashboard/supplier_report",
      icon: <AssessmentIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.requests.includes("browse")) {
    reports_children.push({
      title: t("Report žádosti"),
      path: "/dashboard/report_requests",
      icon: <PlaylistAddCheckCircleIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.suppliers.includes("rewards_report")) {
    reports_children.push({
      title: t("Odměny dodavatelům"),
      path: "/dashboard/rewards_report",
      icon: <EmojiEventsIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.recruitment.includes("report")) {
    reports_children.push({
      title: t("Report Nábor"),
      path: "/dashboard/report_recruitment",
      icon: <BarChartIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("report_people")) {
    reports_children.push({
      title: t("Počet pracovníků v DB"),
      path: "/dashboard/report_people",
      icon: <ShowChartIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.salaries.includes("personal_report")) {
    reports_children.push({
      title: t("Můj report"),
      path: "/dashboard/personal_report",
      icon: <PrintIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.coordinator_money_requests.includes("browse")) {
    reports_children.push({
      title: t("Požadavky Fidoo"),
      path: "/dashboard/coordinator_money_requests",
      icon: <AddCardIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.coordinators_expences.includes("full")) {
    reports_children.push({
      title: t("Report výdaje koordinatoři"),
      path: "/dashboard/coordinator_expences_report",
      icon: <MonetizationOnIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.coordinators_expences.includes("cashier_report")) {
    reports_children.push({
      title: t("Report pokladníka"),
      path: "/dashboard/cashier_report",
      icon: <BarChartIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.feedbacks.includes("browse")) {
    reports_children.push({
      title: t("Zpětná vazba"),
      path: "/dashboard/feedbacks",
      icon: <InsertCommentIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.meeting_records.includes("browse")) {
    reports_children.push({
      title: t("Zapisy z porad"),
      path: "/dashboard/meeting_records",
      icon: <MicIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.customer_reports.includes("browse")) {
    reports_children.push({
      title: t("Report zakázky"),
      path: "/dashboard/customer_reports",
      icon: <BarChartIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.vacancies_report.includes("browse")) {
    reports_children.push({
      title: t("Report požadávků lidí"),
      path: "/dashboard/vacancies_report",
      icon: <HailIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.settings.includes("phone_calls")) {
    reports_children.push({
      title: t("Nahrávky telefonních hovorů"),
      path: "/dashboard/phone_calls",
      icon: <PhonePausedIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.employees.includes("report_suppliers")) {
    reports_children.push({
      title: t("Report dodavatelů"),
      path: "/dashboard/report_suppliers",
      icon: <BusinessCenterIcon fontSize="small" />,
    });
  }

  // Accommodation childrens

  if (
    policy_action_tree.agency_accommodations.includes("freespace") ||
    policy_action_tree.agency_accommodations.includes("full")
  ) {
    accommodation_children.push({
      title: t("Report ubytování"),
      path: "/dashboard/report_accommodation",
      icon: <ApartmentIcon fontSize="small" />,
    });
  }

  if (
    policy_action_tree.agency_accommodations.includes("reservation") ||
    policy_action_tree.agency_accommodations.includes("delete_reserv")
  ) {
    accommodation_children.push({
      title: t("Report rezervace"),
      path: "/dashboard/report_reservation",
      icon: <BookOnlineIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.agency_accommodations.includes("reception")) {
    accommodation_children.push({
      title: t("Recepční - hotovost"),
      path: "/dashboard/report_reception",
      icon: <AddToQueueIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("inventary")) {
    accommodation_children.push({
      title: t("Report inventář"),
      path: "/dashboard/report_items",
      icon: <ChairIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("collection")) {
    accommodation_children.push({
      title: t("Sbírka"),
      path: "/dashboard/report_collection",
      icon: <MonetizationOnIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.agency_accommodations.includes("moving")) {
    accommodation_children.push({
      title: t("Stěhování"),
      path: "/dashboard/report_moving",
      icon: <LocalShippingIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.employees.includes("washing_machines")) {
    accommodation_children.push({
      title: t("Prádelna"),
      path: "/dashboard/washing_machines",
      icon: <LocalLaundryService fontSize="small" />,
    });
  }

  if (policy_action_tree.agency_accommodations.includes("driver")) {
    accommodation_children.push({
      title: t("Rezervace ubytování"),
      path: "/dashboard/reservation",
      icon: <ApartmentIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("accommodations_fines")) {
    accommodation_children.push({
      title: t("Škody na ubytovnach"),
      path: "/dashboard/report_fines",
      icon: <AttachMoneyIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.agency_accommodations.includes("ubyport")) {
    accommodation_children.push({
      title: t("Ubyport"),
      path: "/dashboard/ubyport",

      icon: <PoliceIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.agency_accommodations.includes("statistic")) {
    accommodation_children.push({
      title: t("Statistika ubytování"),
      path: "/dashboard/accommodation_statistic",

      icon: <BarChartIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("accommodations"))
    accommodation_children.push({
      title: t("Ubytovny"),
      path: "/dashboard/accommodations",
      icon: <ApartmentIcon fontSize="small" />,
    });

  // Koordinatory

  if (policy_action_tree.downpayments.includes("employee_report"))
    coordinator_children.push({
      title: t("Požadavky zálohy"),
      path: "/dashboard/downpayments_requests",
      icon: <MonetizationOnIcon fontSize="small" />,
    });

  if (policy_action_tree.employees.includes("dochazka"))
    coordinator_children.push({
      title: t("Docházka"),
      path: "/dashboard/dochazka/all",
      icon: <AccessAlarmIcon fontSize="small" />,
      children: dochazka_children,
    });
  if (policy_action_tree.medical_checkups.includes("browse"))
    coordinator_children.push({
      title: t("Lékařské prohlídky"),
      path: "/dashboard/medical_checkups",
      icon: <LocalHospitalIcon fontSize="small" />,
    });
  if (policy_action_tree.coordinators_expences.includes("browse"))
    coordinator_children.push({
      title: t("Osobní vyučtování"),
      path: "/dashboard/coordinators_expences",
      icon: <AttachMoneyIcon fontSize="small" />,
    });
  if (policy_action_tree.accounting_requests.includes("browse"))
    coordinator_children.push({
      title: t("Požadavky účetní"),
      path: "/dashboard/accounting_requests",
      icon: <AttachMoneyIcon fontSize="small" />,
    });
  if (policy_action_tree.dailycoordinator_reports.includes("browse"))
    coordinator_children.push({
      title: t("Denní report koordinátora"),
      path: "/dashboard/daily_coordinator_reports",
      icon: <HowToRegIcon fontSize="small" />,
    });
  if (policy_action_tree.hours_plans_approvals.includes("browse"))
    coordinator_children.push({
      title: t("Schválení plánu"),
      path: "/dashboard/plans_approvals",
      icon: <CheckCircleIcon fontSize="small" />,
    });

  // Finance

  if (policy_action_tree.projects.includes("browse")) {
    finance_children.push({
      title: t("Projekty"),
      path: "/dashboard/projects",
      icon: <AccountTree fontSize="small" />,
    });
  }
  if (policy_action_tree.sub_projects.includes("browse")) {
    finance_children.push({
      title: t("Podprojekty"),
      path: "/dashboard/sub_projects",
      icon: <AccountTree fontSize="small" />,
    });
  }

  if (policy_action_tree.supplier_invoices.includes("browse")) {
    finance_children.push({
      title: t("Dodavatele (faktury)"),
      path: "/dashboard/supplier_invoices",
      icon: <AccessibilityIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.clients.includes("browse")) {
    finance_children.push({
      title: t("Odběratele (faktury)"),
      path: "/dashboard/clients",
      icon: <AccessibilityIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.supplier_account_numbers.includes("browse")) {
    finance_children.push({
      title: t("Čísla dodavatelských účtů"),
      path: "/dashboard/supplier_account_numbers",
      icon: <AccessibilityIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.received_invoices.includes("browse")) {
    finance_children.push({
      title: t("Přijaté faktury"),
      path: "/dashboard/received_invoices",
      icon: <AssignmentIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("pending_invoices")) {
    finance_children.push({
      title: t("Platby faktur"),
      path: "/dashboard/pending_invoices",
      icon: <AssignmentIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.wire_transfers.includes("browse")) {
    finance_children.push({
      title: t("Bankovní převody"),
      path: "/dashboard/wire_transfers",
      icon: <Paid fontSize="small" />,
    });
  }

  if (policy_action_tree.additional_texts.includes("browse")) {
    finance_children.push({
      title: t("Dodatečné texty"),
      path: "/dashboard/additional_texts",
      icon: <Category fontSize="small" />,
    });
  }

  if (policy_action_tree.invoice_item_names.includes("browse")) {
    finance_children.push({
      title: t("Položky faktur"),
      path: "/dashboard/invoice_item_names",
      icon: <Category fontSize="small" />,
    });
  }

  if (policy_action_tree.issued_invoices.includes("browse")) {
    finance_children.push({
      title: t("Vystavené faktury"),
      path: "/dashboard/issued_invoices",
      icon: <AssignmentIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("account_numbers"))
    finance_children.push({
      title: t("Naše účty"),
      path: "/dashboard/account_numbers",
      icon: <FormatIndentIncreaseIcon fontSize="small" />,
    });

  if (policy_action_tree.payments.includes("browse"))
    finance_children.push({
      title: t("Platby"),
      path: "/dashboard/payments",
      icon: <PaidIcon fontSize="small" />,
    });

  if (policy_action_tree.regular_payments.includes("browse"))
    finance_children.push({
      title: t("Pravidelné platby"),
      path: "/dashboard/regular_payments",
      icon: <EuroIcon fontSize="small" />,
    });

  // Agency childrens

  // if (policy_action_tree.settings.includes("customers"))
  //   agency_settings_children.push({
  //     title: t("Uživatele firmy"),
  //     path: "/dashboard/customers",
  //   });

  if (policy_action_tree.settings.includes("work_places"))
    agency_settings_children.push({
      title: t("Místa výkonu"),
      path: "/dashboard/work_places",
    });

  if (policy_action_tree.settings.includes("agencies"))
    agency_settings_children.push({
      title: t("Naše firmy"),
      path: "/dashboard/agencies",
    });

  if (policy_action_tree.settings.includes("invoice_payers"))
    agency_settings_children.push({
      title: t("Plátci faktur"),
      path: "/dashboard/invoice_payers",
    });

  // if (policy_action_tree.settings.includes("pokladni_types"))
  //   agency_settings_children.push({
  //     title: t("Pokladní - položky"),
  //     path: "/dashboard/pokladni_types",
  //   });

  if (policy_action_tree.settings.includes("dochazka_statuses"))
    agency_settings_children.push({
      title: t("Statusy docházky"),
      path: "/dashboard/dochazka_statuses",
    });

  if (policy_action_tree.settings.includes("fine_types"))
    agency_settings_children.push({
      title: t("Typy pokut"),
      path: "/dashboard/fine_types",
    });

  if (policy_action_tree.departments.includes("browse")) {
    agency_settings_children.push({
      title: t("Střediska"),
      path: "/dashboard/departments",
    });
  }

  if (policy_action_tree.customer_departments.includes("browse")) {
    agency_settings_children.push({
      title: t("Uživatele ve střediskach"),
      path: "/dashboard/customer_departments",
    });
  }

  if (policy_action_tree.employee_customers.includes("browse")) {
    agency_settings_children.push({
      title: t("Koordinatoři v uživatelích"),
      path: "/dashboard/employee_customers",
    });
  }
  if (policy_action_tree.medical_checkup_types.includes("browse")) {
    agency_settings_children.push({
      title: t("Prohlídky typy"),
      path: "/dashboard/medical_checkup_types",
    });
  }
  if (policy_action_tree.place_addresses.includes("browse")) {
    agency_settings_children.push({
      title: t("Adresy místa"),
      path: "/dashboard/place_addresses",
    });
  }
  if (policy_action_tree.customer_states.includes("browse")) {
    agency_settings_children.push({
      title: t("Obchodní fáze"),
      path: "/dashboard/customer_states",
    });
  }
  if (
    policy_action_tree.customer_start_requirement_conditions.includes("browse")
  ) {
    agency_settings_children.push({
      title: t("Typy požadavků na rozjezd"),
      path: "/dashboard/customer_start_requirement_conditions",
    });
  }

  // Customers children
  if (policy_action_tree.customers.includes("browse")) {
    customers_children.push({
      title: t("Pobočky"),
      path: "/dashboard/customers_firm",
    });
  }
  if (policy_action_tree.customer_legals.includes("browse")) {
    customers_children.push({
      title: t("Právnické osoby"),
      path: "/dashboard/customer_legals",
    });
  }

  //Employment department children

  if (policy_action_tree.employment_department_infos.includes("browse")) {
    employment_department_children.push({
      title: t("Informační karta"),
      path: "/dashboard/employment_department_infos",
    });
  }
  if (policy_action_tree.employment_department_notices.includes("browse")) {
    employment_department_children.push({
      title: t("Sdělení"),
      path: "/dashboard/employment_department_notices",
    });
  }

  // Mantenance children
  maintenance_children.push(
    {
      title: t("Ubytování"),
      path: "/dashboard/maintenance_accommodation",
    },
    {
      title: t("Monthly data"),
      path: "/dashboard/maintenance_monthly_data",
    },
    {
      title: t("Assignments"),
      path: "/dashboard/maintenance_assignments",
    }
  );

  // Columns settings

  if (policy_action_tree.settings.includes("employees_columns"))
    column_settings_children.push({
      title: t("Kolonky lidí"),
      path: "/dashboard/employees_columns",
    });
  if (policy_action_tree.settings.includes("account_numbers_columns"))
    column_settings_children.push({
      title: t("Kolonky naše účty"),
      path: "/dashboard/account_numbers_columns",
    });
  if (policy_action_tree.settings.includes("supplier_invoices_columns"))
    column_settings_children.push({
      title: t("Kolonky faktury dodavatele"),
      path: "/dashboard/supplier_invoices_columns",
    });
  if (policy_action_tree.settings.includes("supplier_account_numbers_columns"))
    column_settings_children.push({
      title: t("Kolonky čísla dodavatelských účtů"),
      path: "/dashboard/supplier_account_numbers_columns",
    });
  if (policy_action_tree.settings.includes("regular_payments_columns"))
    column_settings_children.push({
      title: t("Kolonky pravidelné platby"),
      path: "/dashboard/regular_payments_columns",
    });
  if (policy_action_tree.settings.includes("payments_columns"))
    column_settings_children.push({
      title: t("Kolonky platby"),
      path: "/dashboard/payments_columns",
    });
  if (policy_action_tree.settings.includes("received_invoices_columns"))
    column_settings_children.push({
      title: t("Kolonky P. faktury"),
      path: "/dashboard/received_invoices_columns",
    });
  if (policy_action_tree.settings.includes("projects_columns"))
    column_settings_children.push({
      title: t("Kolonky projekty"),
      path: "/dashboard/projects_columns",
    });
  if (policy_action_tree.settings.includes("wire_transfers_columns"))
    column_settings_children.push({
      title: t("Kolonky bankovní převody"),
      path: "/dashboard/wire_transfers_columns",
    });
  if (policy_action_tree.settings.includes("additional_texts_columns"))
    column_settings_children.push({
      title: t("Kolonky dodatečné texty"),
      path: "/dashboard/additional_texts_columns",
    });
  if (policy_action_tree.settings.includes("invoice_item_names_columns"))
    column_settings_children.push({
      title: t("Kolonky názvy položek faktur"),
      path: "/dashboard/invoice_item_names_columns",
    });
  if (policy_action_tree.settings.includes("issued_invoices_columns"))
    column_settings_children.push({
      title: t("Kolonky V. Faktur"),
      path: "/dashboard/issued_invoices_columns",
    });
  if (policy_action_tree.settings.includes("invoice_items_columns"))
    column_settings_children.push({
      title: t("Kolonky položky faktur"),
      path: "/dashboard/invoice_items_columns",
    });
  if (policy_action_tree.settings.includes("clients_columns"))
    column_settings_children.push({
      title: t("Kolonky odberatelů"),
      path: "/dashboard/clients_columns",
    });
  if (policy_action_tree.settings.includes("downpayments"))
    column_settings_children.push({
      title: t("Kolonky zálohy"),
      path: "/dashboard/downpayments_columns",
    });
  if (policy_action_tree.settings.includes("employments_columns"))
    column_settings_children.push({
      title: t("Kolonky pracovních poměrů"),
      path: "/dashboard/employments_columns",
    });
  if (policy_action_tree.settings.includes("assignments_columns"))
    column_settings_children.push({
      title: t("Kolonky přiřazení"),
      path: "/dashboard/assignments_columns",
    });
  if (policy_action_tree.settings.includes("cars_columns"))
    column_settings_children.push({
      title: t("Kolonky aut"),
      path: "/dashboard/cars_columns",
    });
  if (policy_action_tree.settings.includes("medical_checkup"))
    column_settings_children.push({
      title: t("Kolonky Prohlídky typy"),
      path: "/dashboard/medical_checkup_types_columns",
    });
  if (policy_action_tree.settings.includes("medical_checkup"))
    column_settings_children.push({
      title: t("Kolonky Lékařské prohlídky"),
      path: "/dashboard/medical_checkups_columns",
    });
  if (policy_action_tree.settings.includes("visas_columns"))
    column_settings_children.push({
      title: t("Kolonky víza"),
      path: "/dashboard/visas_columns",
    });
  if (policy_action_tree.settings.includes("suppliers_columns"))
    column_settings_children.push({
      title: t("Kolonky dodavetelů"),
      path: "/dashboard/suppliers_columns",
    });
  if (policy_action_tree.settings.includes("agency_accommodations_columns"))
    column_settings_children.push({
      title: t("Kolonky ubytování"),
      path: "/dashboard/agency_accommodations_columns",
    });

  if (policy_action_tree.settings.includes("rests_columns"))
    column_settings_children.push({
      title: t("Kolonky restů"),
      path: "/dashboard/rests_columns",
    });
  if (policy_action_tree.settings.includes("rests_columns"))
    column_settings_children.push({
      title: t("Kolonky restů ubytovatel"),
      path: "/dashboard/rests_ubytovatel_columns",
    });
  if (policy_action_tree.settings.includes("interactions_columns"))
    column_settings_children.push({
      title: t("Kolonky interakce"),
      path: "/dashboard/interactions_columns",
    });

  if (policy_action_tree.settings.includes("fines_columns"))
    column_settings_children.push({
      title: t("Kolonky pokut"),
      path: "/dashboard/fines_columns",
    });
  if (policy_action_tree.settings.includes("communications_columns"))
    column_settings_children.push({
      title: t("Kolonky komunikace"),
      path: "/dashboard/communications_columns",
    });
  if (policy_action_tree.settings.includes("departments_columns"))
    column_settings_children.push({
      title: t("Kolonky střediska"),
      path: "/dashboard/departments_columns",
    });
  if (policy_action_tree.settings.includes("customer_departments_columns"))
    column_settings_children.push({
      title: t("Kolonky uživatele ve střediskach"),
      path: "/dashboard/customer_departments_columns",
    });
  if (policy_action_tree.settings.includes("employee_customers_columns"))
    column_settings_children.push({
      title: t("Kolonky koordinatoři v uživatelích"),
      path: "/dashboard/employee_customers_columns",
    });
  if (policy_action_tree.settings.includes("accommodations_columns"))
    column_settings_children.push({
      title: t("Kolonky ubytovny"),
      path: "/dashboard/accommodations_columns",
    });
  if (policy_action_tree.settings.includes("ubyports_columns"))
    column_settings_children.push({
      title: t("Kolonky ubyport"),
      path: "/dashboard/ubyports_columns",
    });
  if (
    policy_action_tree.settings.includes("coordinator_expences_types_columns")
  )
    column_settings_children.push({
      title: t("Kolonky Pokladní položky"),
      path: "/dashboard/coordinator_expences_types_columns",
    });
  if (policy_action_tree.settings.includes("coordinators_expences_columns"))
    column_settings_children.push({
      title: t("Kolonky Osobní vyučtování"),
      path: "/dashboard/coordinators_expences_columns",
    });
  if (
    policy_action_tree.settings.includes("coordinator_money_requests_columns")
  )
    column_settings_children.push({
      title: t("Kolonky Požadavky Fidoo"),
      path: "/dashboard/coordinator_money_requests_columns",
    });
  if (policy_action_tree.settings.includes("place_addresses_columns"))
    column_settings_children.push({
      title: t("Kolonky Adresy místa"),
      path: "/dashboard/place_addresses_columns",
    });
  if (policy_action_tree.settings.includes("sub_projects_columns"))
    column_settings_children.push({
      title: t("Kolonky Podprojekty"),
      path: "/dashboard/sub_projects_columns",
    });
  if (policy_action_tree.settings.includes("customers_firm_columns"))
    column_settings_children.push({
      title: t("Kolonky Uživatele firmy"),
      path: "/dashboard/customers_columns",
    });
  if (policy_action_tree.settings.includes("customers_interactions_columns"))
    column_settings_children.push({
      title: t("Kolonky Interakcii Uživatele"),
      path: "/dashboard/customer_interactions_columns",
    });
  if (policy_action_tree.settings.includes("customer_legals_columns"))
    column_settings_children.push({
      title: t("Kolonky Právnická osoba"),
      path: "/dashboard/customer_legals_columns",
    });
  if (policy_action_tree.settings.includes("customer_states_columns"))
    column_settings_children.push({
      title: t("Kolonky Obchodní fáze"),
      path: "/dashboard/customer_states_columns",
    });
  if (
    policy_action_tree.settings.includes(
      "customer_start_requirement_conditions_columns"
    )
  )
    column_settings_children.push({
      title: t("Kolonky Typy požadavků na rozjezd"),
      path: "/dashboard/customer_start_requirement_conditions_columns",
    });
  if (
    policy_action_tree.settings.includes("customer_start_requirements_columns")
  )
    column_settings_children.push({
      title: t("Kolonky Požadavky na rozjezd"),
      path: "/dashboard/customer_start_requirements_columns",
    });
  if (
    policy_action_tree.settings.includes(
      "customer_start_requirement_propositions_columns"
    )
  )
    column_settings_children.push({
      title: t("Kolonky Doporučení požadavky na rozjezd"),
      path: "/dashboard/customer_start_requirement_propositions_columns",
    });

  if (
    policy_action_tree.settings.includes("employment_department_infos_columns")
  )
    column_settings_children.push({
      title: t("Kolonky Úřad práce - Informační karta"),
      path: "/dashboard/employment_department_infos_columns",
    });
  if (
    policy_action_tree.settings.includes(
      "employment_department_notices_columns"
    )
  )
    column_settings_children.push({
      title: t("Kolonky Úřad práce - Sdělení"),
      path: "/dashboard/employment_department_notices_columns",
    });
  if (policy_action_tree.settings.includes("accounting_requests_columns"))
    column_settings_children.push({
      title: t("Kolonky Požadavky účetní"),
      path: "/dashboard/accounting_requests_columns",
    });
  if (policy_action_tree.settings.includes("feedbacks_columns"))
    column_settings_children.push({
      title: t("Kolonky Zpětná vazba"),
      path: "/dashboard/feedbacks_columns",
    });
  if (policy_action_tree.settings.includes("dailycoordinator_reports_columns"))
    column_settings_children.push({
      title: t("Kolonky Denní report koordinátora"),
      path: "/dashboard/daily_coordinator_reports_columns",
    });
  if (policy_action_tree.settings.includes("customer_reports_reports_columns"))
    column_settings_children.push({
      title: t("Kolonky Report zakázky"),
      path: "/dashboard/customer_reports_columns",
    });
  if (policy_action_tree.settings.includes("meeting_records_columns"))
    column_settings_children.push({
      title: t("Kolonky Zapisy z porad"),
      path: "/dashboard/meeting_records_columns",
    });
  if (policy_action_tree.settings.includes("newsletters_columns"))
    column_settings_children.push({
      title: t("Kolonky Reklamy"),
      path: "/dashboard/newsletters_columns",
    });

  // Settings

  if (policy_action_tree.settings.includes("users"))
    settings_children.push({
      title: t("Uživatele"),
      path: "/dashboard/users",
      icon: <UsersIcon fontSize="small" />,
      children: [
        // {
        //   title: t("Účty zaměstnanců AWA"),
        //   path: "/dashboard/users_awa",
        // },

        {
          title: t("Role"),
          path: "/dashboard/roles",
        },
        {
          title: t("Práva"),
          path: "/dashboard/permissions",
        },
        {
          title: t("Šablony práv"),
          path: "/dashboard/permissions_templates",
        },
        {
          title: t("Hromadní pravá"),
          path: "/dashboard/permissions_mass",
        },
      ],
    });

  if (policy_action_tree.settings.includes("labour_offices")) {
    settings_children.push({
      title: t("Úřady práce"),
      path: "/dashboard/labour_offices",
      icon: <MapsHomeWork fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("expense_types")) {
    settings_children.push({
      title: t("Typy příjmů a výdajů"),
      path: "/dashboard/expense_types",
      icon: <ExposureIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("inventory_items")) {
    settings_children.push({
      title: t("Inventář"),
      path: "/dashboard/inventory_items",
      icon: <TableBarIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.newsletters.includes("browse")) {
    settings_children.push({
      title: t("Reklamy"),
      path: "/dashboard/newsletters",
      icon: <CampaignIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.requests.includes("create_types")) {
    settings_children.push({
      title: t("Typy požadavků"),
      path: "/dashboard/requests_types",
      icon: <PlaylistAddCircleIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("labour_offices")) {
    settings_children.push({
      title: t("Úřady práce odeslané"),
      path: "/dashboard/labour_office_notifications",
      icon: <MapsHomeWork fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("translations"))
    settings_children.push({
      title: t("Překlady"),
      path: "/dashboard/translations",
      icon: <TranslateIcon fontSize="small" />,
      children: [
        {
          title: t("Ruština"),
          path: "/dashboard/translations/ru",
        },
      ],
    });

  if (
    policy_action_tree.settings.includes("employees_columns") ||
    policy_action_tree.settings.includes("employments_columns") ||
    policy_action_tree.settings.includes("assignments_columns") ||
    policy_action_tree.settings.includes("visas_columns") ||
    policy_action_tree.settings.includes("suppliers_columns") ||
    policy_action_tree.settings.includes("agency_accommodations_columns") ||
    policy_action_tree.settings.includes("rests_columns") ||
    policy_action_tree.settings.includes("fines_columns") ||
    policy_action_tree.settings.includes("communications_columns") ||
    policy_action_tree.settings.includes("supplier_invoices_columns") ||
    policy_action_tree.settings.includes("supplier_account_numbers_columns") ||
    policy_action_tree.settings.includes("projects_columns") ||
    policy_action_tree.settings.includes("account_numbers_columns")
  )
    settings_children.push({
      title: t("Kolonky"),
      path: "/dashboard/columns_employees",
      icon: <ViewWeekIcon fontSize="small" />,
      children: column_settings_children,
    });

  if (policy_action_tree.coordinator_expences_types.includes("browse"))
    settings_children.push({
      title: t("Pokladní položky"),
      path: "/dashboard/coordinator_expences_types",
      icon: <ExposureIcon fontSize="small" />,
    });
  if (policy_action_tree.settings.includes("payment"))
    settings_children.push({
      title: t("Platby"),
      path: "/dashboard/payment",
      icon: <CreditScoreIcon fontSize="small" />,
    });

  if (policy_action_tree.settings.includes("companies")) {
    settings_children.push({
      title: t("Společnosti"),
      path: "/dashboard/company",
      icon: <BusinessCenterIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.settings.includes("mail_templates"))
    settings_children.push({
      title: t("Šablóny emailů"),
      path: "/dashboard/mail_templates",
      icon: <DescriptionIcon fontSize="small" />,
    });

  if (policy_action_tree.settings.includes("notifications"))
    settings_children.push({
      title: t("Notifikace"),
      path: "/dashboard/notifications",
      icon: <NotificationsActiveIcon fontSize="small" />,
    });
  if (policy_action_tree.cars.includes("browse"))
    settings_children.push({
      title: t("Auta"),
      path: "/dashboard/cars",
      icon: <DirectionsCarIcon fontSize="small" />,
    });

  if (policy_action_tree.settings.includes("ondemand_log"))
    settings_children.push({
      title: t("Log zobrazení"),
      path: "/dashboard/ondemand_log",
      icon: <PasswordIcon fontSize="small" />,
    });
  if (policy_action_tree.settings.includes("documents"))
    settings_children.push({
      title: t("Značky dokumentů"),
      path: "/dashboard/documents/tags",
      icon: <LocalOfferIcon />,
    });
  if (policy_action_tree.settings.includes("documents"))
    settings_children.push({
      title: t("Šablóny dokumentů"),
      path: "/dashboard/documents",
      icon: <Summarize fontSize="small" />,
    });

  if (policy_action_tree.settings.includes("rewards"))
    settings_children.push({
      title: t("Odměny"),
      path: "/dashboard/rewards",
      icon: <MonetizationOnIcon fontSize="small" />,
    });

  if (policy_action_tree.reward_rule_blocks.includes("browse"))
    settings_children.push({
      title: t("Bloky pravidel odměny"),
      path: "/dashboard/reward_rule_blocks",
      icon: <AccountTree fontSize="small" />,
    });
  if (policy_action_tree.reward_rules.includes("browse"))
    settings_children.push({
      title: t("Pravidla odměn"),
      path: "/dashboard/reward_rules",
      icon: <AccountTree fontSize="small" />,
    });
  if (policy_action_tree.bonus_rule_blocks.includes("browse"))
    settings_children.push({
      title: t("Bloky pravidel bonusov"),
      path: "/dashboard/bonus_rule_blocks",
      icon: <AccountTree fontSize="small" />,
    });
  if (policy_action_tree.bonus_rules.includes("browse"))
    settings_children.push({
      title: t("Bonusová pravidla"),
      path: "/dashboard/bonus_rules",
      icon: <AccountTree fontSize="small" />,
    });
  if (policy_action_tree.settings.includes("forms"))
    settings_children.push({
      title: t("Formuláře"),
      path: "/dashboard/forms",
      icon: <FormatIndentIncreaseIcon fontSize="small" />,
    });

  if (policy_action_tree.settings.includes("settings_agencies"))
    settings_children.push({
      title: t("Nastavení agentury"),
      path: "/dashboard/agencies",
      icon: <SettingsApplicationsIcon fontSize="small" />,
      children: agency_settings_children,
    });

  if (policy_action_tree.settings.includes("other_settings"))
    settings_children.push({
      title: t("Ostatní nastavení"),
      path: "/dashboard/other_settings",

      icon: <SettingsIcon fontSize="small" />,
    });

  if (policy_action_tree.settings.includes("excel_import"))
    settings_children.push({
      title: t("Importovat z Excelu"),
      path: "/dashboard/excel",

      icon: <SettingsIcon fontSize="small" />,
    });

  if (isAdmin)
    settings_children.push({
      title: t("Údržba"),
      path: "/dashboard/maintenance",
      icon: <SettingsIcon fontSize="small" />,
      children: maintenance_children,
    });

  //MZDY childrens

  if (policy_action_tree.mzdy.includes("browse")) {
    mzdy_children.push({
      title: t("Report Mezd"),
      path: "/dashboard/report_mezd",
      icon: <MonetizationOnIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.hours_plans.includes("browse")) {
    mzdy_children.push({
      title: t("Plán hodin"),
      path: "/dashboard/hours_plans",
      icon: <AccessTimeIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.salaries.includes("browse"))
    mzdy_children.push({
      title: t("Výplaty"),
      path: "/dashboard/salaries_report",
      icon: <AttachMoneyIcon fontSize="small" />,
    });

  if (policy_action_tree.minus_mzdy.includes("browse"))
    mzdy_children.push({
      title: t("Minusové výplaty"),
      path: "/dashboard/minus_salaries_report",
      icon: <DoNotDisturbOnIcon fontSize="small" />,
    });

  if (policy_action_tree.commission_reports.includes("browse")) {
    mzdy_children.push({
      title: t("Report provize"),
      path: "/dashboard/commission_report",
      icon: <AttachMoneyIcon fontSize="small" />,
    });
  }

  if (policy_action_tree.downpayments.includes("browse")) {
    mzdy_children.push({
      title: t("Zálohy"),
      path: "/dashboard/downpayments",
      icon: <MonetizationOnIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.reward_payments.includes("browse")) {
    mzdy_children.push({
      title: t("Vyplácení odměn"),
      path: "/dashboard/rewards_report_new",
      icon: <EmojiEventsIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.bonus_payments.includes("browse")) {
    mzdy_children.push({
      title: t("Vyplácení stab. bonusů"),
      path: "/dashboard/bonus_report",
      icon: <WorkspacePremiumIcon fontSize="small" />,
    });
  }
  if (policy_action_tree.provision_payments.includes("browse")) {
    mzdy_children.push({
      title: t("Vyplácení provize"),
      path: "/dashboard/provision_report",
      icon: <MonetizationOnIcon fontSize="small" />,
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!openMobile) {
      setSearch("");
    }
  }, [openMobile]);

  const [filteredSections, setFilteredSections] = useState(sections);

  const searchItems = (items, searchQuery) => {
    const normalizedSearch = searchQuery
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    return items
      .map((item) => {
        const normalizedTitle = item.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const matches = normalizedTitle.includes(normalizedSearch);

        let filteredChildren = [];
        if (item.children) {
          filteredChildren = searchItems(item.children, searchQuery);
        }

        if (matches || filteredChildren.length > 0) {
          return {
            ...item,
            isOpen: true,
            children:
              filteredChildren.length > 0 ? filteredChildren : undefined,
          };
        }

        return null;
      })
      .filter((item) => item !== null);
  };

  useEffect(() => {
    if (search) {
      const newSections = sections
        .map((section) => {
          const newItems = searchItems(section.items, search);

          return {
            ...section,
            isOpen: newItems.length > 0,
            items: newItems,
          };
        })
        .filter((section) => section.items.length > 0);

      setFilteredSections(newSections);
    } else {
      setFilteredSections(sections);
    }
  }, [search]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 1,
          }}
        >
          <TextField
            fullWidth
            placeholder={t("Vyhledat")}
            value={search}
            onChange={handleSearchChange}
            variant="outlined"
          />
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {filteredSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              isOpen={section.isOpen} // Добавляем проверку раскрытия секции
              {...section}
            />
          ))}
        </Box>
        {isAdmin && (
          <Button sx={{ m: 1 }} onClick={testRequest}>
            Test
          </Button>
        )}
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
